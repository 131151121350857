import { DedupedSearchQueryItem, SearchQueryExtract } from "@/types/types";
import { getOverlayedHighlights } from "@/utils/search";
import { plural } from "@/utils/utils";
import Markdown from "markdown-to-jsx";
import { ReactElement, useState } from "react";
import { Card } from "../ui/card";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Button } from "../ui/button";
import Source from "../Source";

function getVisibleExtracts(extracts: SearchQueryExtract[], showAllExtracts: boolean) {
    if (showAllExtracts) {
        return extracts;
    }

    const [first] = extracts;
    return [first];
}

function Highlight(props: { children: ReactElement }) {
    return <span className="font-citation text-system-primary bg-citation-default">{props.children}</span>
}

function ShowMoreExtracts({ extracts, showAllExtracts, handleShowAllExtracts, handleHideExtracts }: {
    extracts: SearchQueryExtract[],
    showAllExtracts: boolean,
    handleShowAllExtracts: () => void,
    handleHideExtracts: () => void,
}) {

    const total = extracts.length;
    if (total === 1) { return <></> };

    const remaining = total - 1;

    if (showAllExtracts) {
        return (
            <div>
                <Button variant={"secondary"} size="sm" className="" onClick={() => { handleHideExtracts(); }}>
                    Hide {remaining} {plural("extract", remaining)}
                    <ChevronUp className="size-4 ml-2" />
                </Button>
            </div>
        )
    }

    return (
        <div>
            <Button variant={"secondary"} size={"sm"} className="" onClick={() => { handleShowAllExtracts(); }}>
                Show {remaining} other {plural("extract", remaining)}
                <ChevronDown className="size-4 ml-2" />
            </Button>

        </div>
    )
}

export function SearchResult({ resource: d }: { resource: DedupedSearchQueryItem }) {
    const [showAllExtracts, setShowAllExtracts] = useState(false);
    const visibleExtracts = getVisibleExtracts(d.extracts, showAllExtracts);
    return (
        <Card key={`search_result_card_${d.id}`} className="p-3 pb-6 !rounded-md">
            <div className=''>
                <div className=''>
                    <Source
                        id={d.id}
                        url={d.url}
                        title={d.title}
                        documentLink={d.document_link}
                        metadata={{
                            document_is_part_of_desia_library: d.document_is_part_of_desia_library,
                            document_type_friendly: d.document_type_friendly,
                            document_updated_at_desia: d.document_updated_at_desia,
                            document_source: d.document_source,
                            document_source_details: d.document_source_details
                        }}
                    />

                    <div className="max-w-[calc(100vw-110px)] sm:max-w-[522px] mx-auto pt-4 overflow-auto flex flex-col gap-8">
                        {visibleExtracts.map((extract, idx) => {
                            return (
                                <div key={`extract-${d.id}-${idx}`}>
                                    <div className={`text-muted-foreground overflow-y-scroll ${showAllExtracts ? "" : "max-h-[175px]"}`}>
                                        <Markdown options={{
                                            overrides: {
                                                em: {
                                                    component: Highlight
                                                },
                                            },
                                            wrapper: 'span',
                                            forceBlock: true,
                                        }}>
                                            {getOverlayedHighlights(extract.text, extract.highlight?.passage_text || [])}
                                        </Markdown>
                                    </div>
                                    {(idx === 0 || idx === visibleExtracts.length - 1) && (
                                        <div className={`flex justify-center pt-6 ${showAllExtracts ? '' : ''}`}>
                                            <ShowMoreExtracts
                                                extracts={d.extracts}
                                                showAllExtracts={showAllExtracts}
                                                handleShowAllExtracts={() => { setShowAllExtracts(true); }}
                                                handleHideExtracts={() => { setShowAllExtracts(false); }}
                                            />
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Card>
    )
}
