import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { getFileIcon } from "@/utils/components";
import { Checkbox } from "../ui/checkbox";
import { TypographyBody } from "../ui/Typography";
import ChevronDown from "@/assets/ChevronDown";
import { Dispatch, SetStateAction, useState } from "react";
import { ChevronUp } from "lucide-react";
import { FileStructure } from "@/types/types";

export interface FileTableProps {
    elements: FileStructure[]
    setElements?: Dispatch<SetStateAction<FileStructure[]>>
    showHeader?: boolean
    showCheckbox?: boolean
}

export const FileTable = ({ elements, setElements, showHeader, showCheckbox }: FileTableProps) => {
    const [openedFolders, setOpenedFolders] = useState<number[]>([])

    const rootElements = elements?.filter((v) => v.parent_element_internal_ids.length === 0) || []

    const FileRow = (props: { file: FileStructure, level: number, openedFolders: number[], toggleOpenFolder: (id: number) => void, toggleElement: (element: FileStructure) => void }) => {
        const allSelected = props.file.is_included && !props.file.child_is_excluded
        const partialSelected = props.file.child_is_included

        return (
            <>
                <TableRow key={props.file.internal_element_id} className="transition-all">
                    {showCheckbox && (
                        <TableCell className="font-body text-system-body !py-2 shrink-0 w-[46px]">
                            {props.file.element_type !== 'file' && (
                                <Checkbox
                                    checked={(allSelected || partialSelected)}
                                    disabled={false}
                                    onCheckedChange={() => toggleElement(props.file)}
                                    partial={partialSelected && !allSelected}
                                />
                            )}
                        </TableCell>
                    )}

                    <TableCell className="font-body text-system-body hover:underline hover:cursor-pointer !max-w-[640px] !overflow-hidden !py-2 shrink-0">
                        <div style={{ marginLeft: `${props.level * 30}px` }} className="flex gap-2 items-center">
                            {getFileIcon(props.file.element_type, 'w-6 h-6')}
                            <TypographyBody className="line-clamp-1">
                                {props.file.element_name}
                            </TypographyBody>
                            {(props.file.children_element_internal_ids || []).length > 0 && (
                                <div className="cursor-pointer" onClick={() => toggleOpenFolder(props.file.internal_element_id)}>
                                    {openedFolders.includes(props.file.internal_element_id) ?
                                        <ChevronUp className="w-6 h-6 shrink-0 stroke-[1.5px]" />
                                        :
                                        <ChevronDown className="w-6 h-6 shrink-0" />
                                    }
                                </div>
                            )}
                        </div>
                    </TableCell>
                </TableRow>

                {props.openedFolders.includes(props.file.internal_element_id) && props.file.children_element_internal_ids?.map((childId) => {
                    const childFile = elements.find((v) => v.internal_element_id === childId)

                    if (!childFile) return null
                    if (!(childFile.parent_element_internal_ids.at(-1) === props.file.internal_element_id)) return null

                    return <FileRow
                        file={childFile}
                        level={props.level + 1}
                        openedFolders={openedFolders}
                        toggleOpenFolder={toggleOpenFolder}
                        toggleElement={toggleElement}
                    />
                })}
            </>
        )
    }

    const toggleOpenFolder = (id: number) => {
        if (openedFolders.includes(id)) {
            setOpenedFolders(openedFolders.filter((v) => v !== id))
        } else {
            setOpenedFolders([...openedFolders, id])
        }
    }

    const toggleElement = (element: FileStructure) => {
        const allSelected = element.is_included && !element.is_excluded
        const partialSelected = element.child_is_included

        const updatedElements = elements.map((v) => {
            let updatedElement = v
            let updatedChildElement = v

            if (allSelected) {
                updatedElement = { ...v, is_included: false, child_is_included: false, is_excluded: true, child_is_excluded: false }
                updatedChildElement = { ...v, is_included: false, child_is_included: false, is_excluded: true, child_is_excluded: false }
            } else if (partialSelected) {
                updatedElement = { ...v, is_included: true, child_is_included: false, is_excluded: false, child_is_excluded: false }
                updatedChildElement = { ...v, is_included: true, child_is_included: false, is_excluded: false, child_is_excluded: false }
            } else {
                updatedElement = { ...v, is_included: true, child_is_included: false, is_excluded: false, child_is_excluded: false }
                updatedChildElement = { ...v, is_included: true, child_is_included: false, is_excluded: false, child_is_excluded: false }
            }

            if (element.children_element_internal_ids?.includes(v.internal_element_id)) {
                return updatedChildElement
            } else if (element.internal_element_id === v.internal_element_id) {
                return updatedElement
            }

            return v
        })

        setElements?.(() => updatedElements)

        updateParentElements(element)
    }

    const updateParentElements = (element: FileStructure) => {
        const parentElement = elements.find((v) => v.internal_element_id === element.parent_element_internal_ids.at(-1))

        if (!parentElement) return

        setElements?.((elements) => {
            const level = elements.filter((v) => v.parent_element_internal_ids.at(-1) === element.parent_element_internal_ids.at(-1) && v.element_type !== 'file')

            let updatedParentElement = elements.find((v) => v.internal_element_id === parentElement?.internal_element_id)

            if (!updatedParentElement) return []

            if (level.every((v) => v.is_included)) {
                updatedParentElement = { ...updatedParentElement, is_included: true, child_is_included: false, is_excluded: false, child_is_excluded: false }
            } else if (level.some((v) => v.is_included || v.child_is_included)) {
                updatedParentElement = { ...updatedParentElement, is_included: false, child_is_included: true, is_excluded: false, child_is_excluded: true }
            } else {
                updatedParentElement = { ...updatedParentElement, is_included: false, child_is_included: false, is_excluded: false, child_is_excluded: false }
            }

            return elements.map((v) => v.internal_element_id === updatedParentElement.internal_element_id ? updatedParentElement : v)
        })

        updateParentElements(parentElement)
    }

    return (
        <Table>
            {showHeader && (
                <TableHeader>
                    <TableRow>
                        {showCheckbox && (
                            <TableHead className="font-body-strong text-system-primary w-[44px]"></TableHead>
                        )}
                        <TableHead className="font-body-strong text-system-primary !max-w-[640px] !overflow-hidden">File name</TableHead>
                        <TableHead className="font-body-strong text-system-primary whitespace-nowrap">Uploaded on</TableHead>
                        <TableHead className="font-body-strong text-system-primary">File size</TableHead>
                    </TableRow>
                </TableHeader>
            )}

            <TableBody>
                {rootElements.map((d) => (
                    <>
                        <FileRow
                            file={d}
                            level={0}
                            openedFolders={openedFolders}
                            toggleOpenFolder={toggleOpenFolder}
                            toggleElement={(v) => toggleElement(v)}
                        />
                    </>
                ))}
            </TableBody>
        </Table>
    )
}