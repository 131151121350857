import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/shadcn/utils"

const badgeVariants = cva(
  "inline-flex items-center rounded-full font-label px-3 py-0.5 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        blue: "bg-sky-100 text-blue-600",
        green: "bg-lime-100 text-emerald-700",
        red: "bg-messaging-negative-background text-rose-700",
        orange: "bg-amber-100 text-[#C22F00]",
        purple: "bg-violet-100 text-purple-800",
        teal: "bg-teal-100 text-cyan-800",
      },
    },
    defaultVariants: {
      variant: "blue",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
  VariantProps<typeof badgeVariants> { }

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
