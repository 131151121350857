
import { Button } from "../ui/button";
import ArrowRight from "@/assets/ArrowRight";
import { z } from 'zod'
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"
import { Input } from "../ui/input";
import { useNavigate } from "react-router-dom";
// import { ExternalLink } from "lucide-react";
import { Integration } from "@/types/types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { setCredential, uploadCredentials } from "./integrationSlice";
import { CustomAlert } from "../CustomAlert";
import { Loader2 } from "lucide-react";

const oneDriveFormSchema = z.object({
    client_secret: z.string().min(1, "Please ensure Secret ID isn't empty"),
    client_id: z.string().min(1, "Please ensure Application ID isn't empty"),
    tenant_id: z.string().min(1, "Please ensure Directory ID isn't empty")
})

export const IntegrationAuthentication = ({ integration }: { integration: Integration }) => {
    const credential = useSelector((state: RootState) => state.integration.credential)

    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()

    const form = useForm<z.infer<typeof oneDriveFormSchema>>({
        resolver: zodResolver(oneDriveFormSchema),
        defaultValues: {
            client_secret: credential.data?.client_secret || '',
            client_id: credential.data?.client_id || '',
            tenant_id: credential.data?.tenant_id || ''
        },
    })

    const onSubmit = (values: z.infer<typeof oneDriveFormSchema>) => {
        if (credential.status === 'loading') return

        dispatch(setCredential({
            tenant_id: values.tenant_id,
            client_id: values.client_id,
            client_secret: values.client_secret
        }))

        dispatch(uploadCredentials({
            integration_id: integration.integration_id,
            tenant_id: values.tenant_id,
            client_id: values.client_id,
            client_secret: values.client_secret
        }))
    }

    return (
        <div className="w-full flex flex-col gap-6">
            {/* <Button variant='inline' className="mx-auto">
                <div className="flex gap-2">
                    Read setup guide

                    <ExternalLink className="h-6 w-6 shrink-0" />
                </div>
            </Button> */}

            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2 overflow-y-auto">
                    <div className="max-w-[320px] mx-auto space-y-2">
                        <FormField
                            control={form.control}
                            name="client_secret"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Secret ID</FormLabel>
                                    <FormMessage />
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="client_id"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Application (client) ID</FormLabel>
                                    <FormMessage />
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="tenant_id"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Directory (tenant) ID</FormLabel>
                                    <FormMessage />
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="pb-10"></div>

                    {credential.status === 'error' && (
                        <div className="pb-6 max-w-[360px] mx-auto">
                            <CustomAlert
                                variant='error'
                                title={credential.errorStatus === 400 ? "The provided credentials do not match." : undefined}
                                description={credential.errorStatus === 400 ? "Please check you have entered them correctly and try again." : "We were not able to authenticate you, please try again."}
                            />
                        </div>
                    )}

                    <div className="flex gap-2 justify-center">
                        <Button variant='secondary' onClick={() => navigate('/integrations')}>
                            Cancel
                        </Button>

                        <Button type="submit">
                            <div className="flex gap-2">
                                Authenticate and select files

                                {credential.status === 'loading' ?
                                    <Loader2 className="w-6 h-6 animate-spin shrink-0" />
                                    :
                                    <ArrowRight className="w-6 h-6 shrink-0" />
                                }
                            </div>
                        </Button>
                    </div>
                </form>
            </Form>
        </div>
    )
}