import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useNavigate } from "react-router";
import { checkDesiaUser } from "../../utils/utils";
import { useContext } from "react";
import { UserContext } from "@/contexts/UserContext";
import { TypographyBody } from "../ui/Typography";
import { FeatureFlagContext } from "@/contexts/FeatureFlagContext";

export function UserMenu({ email }: { email: string }) {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const ff = useContext(FeatureFlagContext);
  const isDesiaUser = checkDesiaUser(user.user);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <span
          className="flex items-center gap-2 rounded-lg text-muted-foreground transition-all hover:text-primary cursor-pointer"
        >
          <img src={user?.user?.picture} className="h-9 w-9 rounded-full" />
          <TypographyBody className="truncate">{email}</TypographyBody>
        </span>
      </DropdownMenuTrigger>
      {isDesiaUser && (
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>My Account</DropdownMenuLabel>
          <DropdownMenuSeparator />

          <DropdownMenuItem
            onClick={() => {
              navigate("/profile");
            }}
          >Profile</DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              navigate("/settings");
            }}
          >Settings
          </DropdownMenuItem>

          {ff.checkFlag('integration: manage') && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => {
                  navigate("/integrations");
                }}
              >Admin settings
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  )
}