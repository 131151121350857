import { ReactNode } from "react";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { TypographyBody } from "./ui/Typography";
import { Button } from "./ui/button";

export function WarnOnAction({ action, title, message, buttonLabel, cancelButtonLabel, warn, children }: { action: () => void, title?: string, message?: string, buttonLabel?: string, cancelButtonLabel?: string, warn?: boolean, children: ReactNode }) {
    return (
        warn === false ?
            <div onClick={() => action()}>
                {children}
            </div>
            :
            <Dialog>
                <DialogTrigger asChild>
                    <div className="hover:cursor-pointer">
                        <div className="pointer-events-none">
                            {children}
                        </div>
                    </div>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>{title || 'You are about to leave this page'}</DialogTitle>
                        <DialogDescription className="pb-8">
                            <TypographyBody>
                                {message || 'Leaving the page means discarding the file selection. Are you sure you want to leave?'}
                            </TypographyBody>
                        </DialogDescription>
                        <DialogFooter>
                            <DialogClose>
                                <Button variant="secondary" className="w-full" onClick={() => action()}>{buttonLabel || 'Leave and discard selection'}</Button>
                            </DialogClose>
                            <DialogClose>
                                <Button className="w-full">
                                    {cancelButtonLabel || 'Stay on page'}
                                </Button>
                            </DialogClose>
                        </DialogFooter>
                    </DialogHeader>
                </DialogContent>
            </Dialog>
    )
}
