import { UserContext } from "@/contexts/UserContext"
import { Integration } from "@/types/types"
import { friendlyOrgName, getIntegrationName } from "@/utils/utils"
import { useContext } from "react"

export function ResourceFilter({ integrations, selectedFilter, handleFilterChange }: { integrations: Integration[], selectedFilter: string, handleFilterChange: (filterName: string) => void }) {
    const userContext = useContext(UserContext)
    const filters: { name: string, value: string, isSelected: boolean }[] = [
        {
            name: 'All documents',
            value: 'all documents',
            isSelected: selectedFilter === 'all documents',
        },
        ...integrations.map((integration) => {
            return {
                name: getIntegrationName(integration.integration_code_name),
                value: integration.integration_code_name,
                isSelected: selectedFilter === integration.integration_code_name,
            }
        }),
        {
            name: 'company-wide',
            value: 'company-wide',
            isSelected: selectedFilter === 'company-wide',
        },
        {
            name: 'Desia library',
            value: 'desia library',
            isSelected: selectedFilter === 'desia library',
        },
    ]
    return (
        <div className="flex gap-8 border-b justify-center">
            {filters.map((f) => {
                return (
                    <div
                        key={f.value}
                        className={`font-label sm:font-body hover:cursor-pointer pb-2 ${f.isSelected ? '!font-label-strong sm:!font-body-strong border-b-2 border-system-primary text-system-primary' : 'text-system-body'}`}
                        onClick={() => {
                            handleFilterChange(f.value)
                        }}
                    >
                        {f.name === 'company-wide' ? friendlyOrgName(userContext.user?.app_metadata.organization_id) : f.name}
                    </div>
                )
            })}
        </div>
    )
}
