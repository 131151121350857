import { WEB_SERVER_ENDPOINT } from '@/constants';
import { ASYNC_STATUS, Integration, IntegrationCredential, IntegrationStage, IntegrationStatus, RequestIntegrationCredential, RequestSynchronizationControl, RequestSynchronizationControlDelete, ResponseIntegrationStructure } from '@/types/types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IntegrationState {
    currentPage: IntegrationStage
    integrations: {
        data: Integration[] | null,
        status: ASYNC_STATUS
    }
    integrationStatus: {
        [id: number]: {
            data: IntegrationStatus | null,
            status: ASYNC_STATUS
        }
    }
    credential: {
        data: IntegrationCredential | null
        status: ASYNC_STATUS
        errorStatus: number | null
    }
    integrationStructure: {
        [id: number]: {
            data: ResponseIntegrationStructure | null
            status: ASYNC_STATUS
            submitStatus: ASYNC_STATUS
            deleteStatus: ASYNC_STATUS
            insertStatus: ASYNC_STATUS
        }
    }
    deleteIntegration: {
        status: ASYNC_STATUS
    }
}

const initialState: IntegrationState = {
    currentPage: 'authentication',
    integrations: {
        data: null,
        status: ASYNC_STATUS.idle
    },
    integrationStatus: {},
    credential: {
        data: null,
        status: ASYNC_STATUS.idle,
        errorStatus: null
    },
    integrationStructure: {},
    deleteIntegration: {
        status: ASYNC_STATUS.idle
    }
}

export const fetchIntegrations = createAsyncThunk<Integration[]>(
    'integration/fetchIntegrations',
    async (_, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/integration/list`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    },
)

export const fetchIntegrationStatus = createAsyncThunk<IntegrationStatus, number>(
    'integration/fetchIntegrationStatus',
    async (id: number, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/integration/${id}/status`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    },
)

export const uploadCredentials = createAsyncThunk<IntegrationStatus, RequestIntegrationCredential>(
    'integration/uploadCredentials',
    async (credential: RequestIntegrationCredential, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/integration/credentials`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(credential)
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue({ ...data, status: response.status })
        }
        return data
    },
)

export const fetchIntegrationStructures = createAsyncThunk<ResponseIntegrationStructure, number>(
    'integration/fetchIntegrationStructures',
    async (id: number, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/integration/${id}/structures`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    },
)

export const uploadSynchronizationControl = createAsyncThunk<string, RequestSynchronizationControl>(
    'integration/uploadSynchronizationControl',
    async (payload: RequestSynchronizationControl, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/integration/synchronization/control`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(payload)
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue({ ...data, status: response.status })
        }
        return data
    },
)

export const deleteIntegration = createAsyncThunk<string, number>(
    'integration/deleteIntegration',
    async (id: number, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/integration/${id}`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    },
)

export const uploadSynchronizationDelete = createAsyncThunk<string, RequestSynchronizationControlDelete>(
    'integration/uploadSynchronizationDelete',
    async (payload: RequestSynchronizationControlDelete, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/integration/synchronization/control/delete`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                internal_elements_ids: payload.internal_elements_ids
            })
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue({ ...data, status: response.status })
        }
        return data
    },
)

export const integrationSlice = createSlice({
    name: 'integration',
    initialState,
    reducers: {
        setCurrentPage: (state, action: PayloadAction<IntegrationStage>) => {
            state.currentPage = action.payload
        },
        setCredential: (state, action: PayloadAction<IntegrationCredential>) => {
            state.credential.data = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchIntegrations.pending, (state) => {
            state.integrations.status = ASYNC_STATUS.loading
            state.integrations.data = null
        })
        builder.addCase(fetchIntegrations.fulfilled, (state, action) => {
            state.integrations.status = ASYNC_STATUS.success
            state.integrations.data = action.payload

        })
        builder.addCase(fetchIntegrations.rejected, (state) => {
            state.integrations.status = ASYNC_STATUS.error
            state.integrations.data = null
        })
        builder.addCase(fetchIntegrationStatus.pending, (state, action) => {
            state.integrationStatus[action.meta.arg] = {
                status: ASYNC_STATUS.loading,
                data: null
            }
        })
        builder.addCase(fetchIntegrationStatus.fulfilled, (state, action) => {
            state.integrationStatus[action.meta.arg] = {
                status: ASYNC_STATUS.success,
                data: action.payload
            }
        })
        builder.addCase(fetchIntegrationStatus.rejected, (state, action) => {
            state.integrationStatus[action.meta.arg] = {
                status: ASYNC_STATUS.error,
                data: null
            }
        })
        builder.addCase(uploadCredentials.pending, (state) => {
            state.credential.errorStatus = null
            state.credential.status = ASYNC_STATUS.loading
        })
        builder.addCase(uploadCredentials.fulfilled, (state) => {
            state.credential.errorStatus = null
            state.credential.status = ASYNC_STATUS.success
            state.currentPage = 'file-selection'
        })
        builder.addCase(uploadCredentials.rejected, (state, action) => {
            // @ts-expect-error
            state.credential.errorStatus = action.payload.status
            state.credential.status = ASYNC_STATUS.error
        })
        builder.addCase(fetchIntegrationStructures.pending, (state, action) => {
            state.integrationStructure[action.meta.arg] = {
                ...state.integrationStructure[action.meta.arg],
                data: null,
                status: ASYNC_STATUS.loading
            }
        })
        builder.addCase(fetchIntegrationStructures.fulfilled, (state, action) => {
            state.integrationStructure[action.meta.arg] = {
                ...state.integrationStructure[action.meta.arg],
                data: action.payload,
                status: ASYNC_STATUS.success
            }
        })
        builder.addCase(fetchIntegrationStructures.rejected, (state, action) => {
            state.integrationStructure[action.meta.arg] = {
                ...state.integrationStructure[action.meta.arg],
                data: null,
                status: ASYNC_STATUS.error
            }
        })
        builder.addCase(uploadSynchronizationControl.pending, (state, action) => {
            state.integrationStructure[action.meta.arg.integration_id] = {
                ...state.integrationStructure[action.meta.arg.integration_id],
                submitStatus: ASYNC_STATUS.loading
            }
        })
        builder.addCase(uploadSynchronizationControl.fulfilled, (state, action) => {
            state.integrationStructure[action.meta.arg.integration_id] = {
                ...state.integrationStructure[action.meta.arg.integration_id],
                submitStatus: ASYNC_STATUS.success
            }
        })
        builder.addCase(uploadSynchronizationControl.rejected, (state, action) => {
            state.integrationStructure[action.meta.arg.integration_id] = {
                ...state.integrationStructure[action.meta.arg.integration_id],
                submitStatus: ASYNC_STATUS.error
            }
        })
        builder.addCase(deleteIntegration.pending, (state) => {
            state.deleteIntegration.status = ASYNC_STATUS.loading
        })
        builder.addCase(deleteIntegration.fulfilled, (state) => {
            state.deleteIntegration.status = ASYNC_STATUS.success
        })
        builder.addCase(deleteIntegration.rejected, (state) => {
            state.deleteIntegration.status = ASYNC_STATUS.error
        })
        builder.addCase(uploadSynchronizationDelete.pending, (state, action) => {
            state.integrationStructure[action.meta.arg.integration_id] = {
                ...state.integrationStructure[action.meta.arg.integration_id],
                deleteStatus: ASYNC_STATUS.loading
            }
        })
        builder.addCase(uploadSynchronizationDelete.fulfilled, (state, action) => {
            state.integrationStructure[action.meta.arg.integration_id] = {
                ...state.integrationStructure[action.meta.arg.integration_id],
                deleteStatus: ASYNC_STATUS.success
            }
        })
        builder.addCase(uploadSynchronizationDelete.rejected, (state, action) => {
            state.integrationStructure[action.meta.arg.integration_id] = {
                ...state.integrationStructure[action.meta.arg.integration_id],
                deleteStatus: ASYNC_STATUS.error
            }
        })
    },
})

export const {
    setCurrentPage,
    setCredential
} = integrationSlice.actions

export default integrationSlice.reducer
