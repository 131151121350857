export interface PageIndicatorProps {
    maxPage: number
    currentPage: number
}

export const PageIndicator = ({ maxPage, currentPage }: PageIndicatorProps) => {
    return (
        <div className="flex gap-2">
            {[...Array(maxPage)].map((_, i) =>
                <div className={`w-2 h-2 rounded-full shrink-0 ${currentPage - 1 === i ? 'bg-system-primary' : 'bg-[#D9D9D9]'}`}></div>
            )}
        </div>
    )
}