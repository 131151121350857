import { Integration } from "@/types/types";

export const mockIntegrations: Integration[] = [
    {
        "integration_id": 1,
        "integration_code_name": "microsoft-onedrive",
        "integration_name": "Office 365 - OneDrive",
        "integration_description": "Microsoft Office 365 OneDrive Service integration. Transfer files for your Desia Library.",
        "integration_is_enabled": true,
        "integration_has_setup": true,
        "integration_created_at": "2024-07-18 08:39:57",
        "integration_updated_at": null
    },
    {
        "integration_id": 2,
        "integration_code_name": "microsoft-sharepoint",
        "integration_name": "Office 365 - Sharepoint",
        "integration_description": "Microsoft Office 365 Sharepoint Service integration. Transfer files for your Desia Library.",
        "integration_is_enabled": true,
        "integration_has_setup": false,
        "integration_created_at": "2024-07-18 08:39:57",
        "integration_updated_at": null
    }
]