import { Integration, IntegrationStage } from "@/types/types"
import { PageIndicator } from "../PageIndicator"
import { TypographyBody, TypographyH3 } from "../ui/Typography"
import { getIntegrationName } from "@/utils/utils"
import { RootState } from "@/store/store"
import { useSelector } from "react-redux"

const IntegrationHeader = ({ integration, currentPage }: { integration: Integration, currentPage: IntegrationStage }) => {
    const integrationStructure = useSelector((state: RootState) => state.integration.integrationStructure)

    const pages: IntegrationStage[] = ['authentication', 'file-selection']

    return (
        <div className="flex flex-col gap-2 text-center">
            <div className="mx-auto">
                <PageIndicator currentPage={pages.findIndex((v) => v === currentPage) + 1} maxPage={pages.length} />
            </div>

            {currentPage === 'authentication' && (
                <>
                    <TypographyH3>
                        {`Connect to ${getIntegrationName(integration.integration_code_name)}`}
                    </TypographyH3>

                    <TypographyBody className="text-system-body">
                        {`Firstly, provide the necessary credentials to securely start the ${getIntegrationName(integration.integration_code_name)} integration.`}
                    </TypographyBody>
                </>
            )}

            {currentPage === 'file-selection' && (
                <>
                    <TypographyH3>
                        Select which files to connect
                    </TypographyH3>

                    {integrationStructure[integration.integration_id]?.status !== 'loading' && (
                        <TypographyBody className="text-system-body">
                            Selected files / folders will only be accessible by members of your team
                        </TypographyBody>
                    )}
                </>
            )}

        </div>
    )
}

export default IntegrationHeader