import logo from "../../assets/logo.svg";
import { Link, useNavigate } from "react-router-dom"
import { ReactNode, useContext, useEffect } from "react";
import { Sidebar } from "./Sidebar";
import { UserMenu } from "./UserMenu";
import { UserContext } from "@/contexts/UserContext";
import { ASSISTANT_ACTIONS, AssistantStoreContext } from "@/contexts/AssistantContext";
import { ChatSummary, DESIA_EVENT, ResponseChatList } from "@/types/types";
import { getTimestamp } from "@/utils/utils";
import { Main } from "./Main";
import { useSocketQuery } from "@/hooks/useSocketQuery";
import { WarnOnNavigate } from "../WarnOnNavigate";
import { TypographyBody } from "../ui/Typography";
import { handleLogout } from "../../utils/utils";
import Menu from '../../assets/Menu'
import LeftToLine from "../../assets/LeftToLine";
import Logout from "../../assets/Logout";
import { LayoutContext } from "@/contexts/LayoutContext";
import { Toast } from "../ui/toast";

type LayoutContainerProps = {
  component: ReactNode;
  tall?: boolean;
  scrollable?: boolean;
}

type LayoutProps = {
  component: ReactNode;
  email: string;
  threads: ChatSummary[];
  tall?: boolean;
  scrollable?: boolean;
}

export function LayoutContainer({ component, tall, scrollable }: LayoutContainerProps) {
  const { store, dispatch } = useContext(AssistantStoreContext);
  const { user } = useContext(UserContext);
  const { executeQuery } = useSocketQuery({
    event: DESIA_EVENT.CHAT_LIST,
    request: {
      requestId: "list",
      timestamp: getTimestamp(),
      params: {}
    },
    options: {
      manual: true,
      cacheTimeoutMs: 60_000,
      callback: (response) => {
        dispatch({
          action: ASSISTANT_ACTIONS.FETCH_THREADS,
          data: response.data as ResponseChatList[]
        })
      }
    }
  });

  useEffect(() => {
    if (user) {
      executeQuery({
        event: DESIA_EVENT.CHAT_LIST,
        request: {
          userId: user?.sub,
          requestId: "list",
          timestamp: getTimestamp(),
          params: {}
        },
      })
    }
  }, [user])

  return (
    <Layout
      component={component}
      email={user?.email || "..."}
      threads={store.list}
      tall={tall}
      scrollable={scrollable}
    />
  )
}

export function Layout({ component, email, threads, tall, scrollable }: LayoutProps) {
  const layoutContext = useContext(LayoutContext)
  const navigate = useNavigate()

  const sidebarContainerStyle = `${layoutContext.showSidebar ? 'md:min-w-[220px] lg:min-w-[320px]' : ''} transition-all `
  const sidebarStyle = `${layoutContext.showSidebar ? 'translate-x-0' : '-translate-x-full'} w-full bg-system-surface z-[20] border-r flex h-screen flex-col gap-10 fixed overflow-y-auto px-8 pt-6 pb-12 md:w-[220px] lg:w-[320px] transition-all`
  const sidebarMenuStyle = `${layoutContext.showSidebar ? 'md:w-0 lg:w-0 hidden' : ''} flex gap-4 items-center fixed p-6 pb-4 w-full md:w-fit bg-system-surface z-[20]`

  useEffect(() => {
    // redirect user to a url after refresh, current use case is redirect to integration loading structure if the user refreshes the page
    const urlLock = localStorage.getItem(`url_lock`)

    if (urlLock) {
      navigate(urlLock)
    }
  }, [])

  return (
    <div className="flex min-h-screen w-full relative overflow-x-hidden">
      <div className={sidebarContainerStyle}>
        <div className={sidebarMenuStyle} >
          <div className="cursor-pointer" onClick={() => layoutContext.toggleShowSidebar(!layoutContext.showSidebar)}>
            <Menu className="w-6 h-6 cursor-pointer " />
          </div>
          <img className="h-7" src={logo} />
        </div>
        <div className={sidebarStyle}>
          <div className="flex flex-col gap-10 w-full">
            <div className="flex gap-2 cursor-pointer" onClick={() => layoutContext.toggleShowSidebar(!layoutContext.showSidebar)}>
              <LeftToLine className="h-6 w-6 stroke-system-primary" />
              <TypographyBody isStrong={true}>
                Hide
              </TypographyBody>
            </div>

            <WarnOnNavigate to="/assistant/ask/" component={
              <Link to="/" className="flex items-center font-semibold">
                <img className="h-9" src={logo} />
              </Link>
            } />
          </div>
          <div className="flex-grow w-full mt-10">
            <Sidebar threads={threads} />
          </div>
          <div className="flex flex-col gap-6">
            <UserMenu email={email} />

            <div className="flex gap-2 cursor-pointer" onClick={() => handleLogout()}>
              <Logout className="w-6 h-6" />

              <TypographyBody isStrong={true}>
                Sign out
              </TypographyBody>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-grow bg">
        <header className="flex h-14 items-center gap-4 lg:h-[60px] md:hidden">
          <div className={`${sidebarMenuStyle}`} >
            <div className="cursor-pointer" onClick={() => layoutContext.toggleShowSidebar(!layoutContext.showSidebar)}>
              <Menu className="w-6 h-6 cursor-pointer top-0 left-0" />
            </div>
            <img className="h-7" src={logo} />
          </div>
        </header>
        <Main component={component} tall={tall} scrollable={scrollable} />
      </div>

      <Toast />
    </div>
  )
}
