import FileText from "@/assets/FileText";
import { getFileIcon, getIntegrationIcon } from "@/utils/components";
import { friendlyOrgName, getIconSrc, getIntegrationName, getLocale, handleOpenLink } from "@/utils/utils";
import { ExternalLink } from "lucide-react";
import { TypographyLabel } from "./ui/Typography";
import { useContext } from "react";
import { UserContext } from "@/contexts/UserContext";
import Diffbot from "@/assets/Diffbot";
import { SourceDocumentMetadata } from "@/types/types";

interface SourceProps {
    id: string
    url: string
    title: string
    text?: string
    documentLink?: string
    showBorder?: boolean
    metadata?: SourceDocumentMetadata
}

const Source = ({ id, url, title, text, documentLink, showBorder, metadata }: SourceProps) => {
    const userContext = useContext(UserContext)
    const isThirdPartyData = metadata?.label === 'third_party_data'
    const isDiffbot = metadata?.source?.toLowerCase().includes('diffbot')
    const isDocument = !id.includes("web")
    const isIntegration = metadata?.document_source === 'integration'
    const locale = getLocale();
    const updatedDateString = metadata?.document_updated_at_desia && new Date(metadata.document_updated_at_desia).toLocaleDateString(locale, {
        day: "numeric",
        month: "short",
        year: "numeric",

    });

    const getHostname = () => {
        try {
            const { hostname } = new URL(url);
            return hostname
        } catch (e) {
            return ''
        }
    }

    const getDomain = (hostname: string) => {
        const replacedDomain = hostname.replaceAll('www.', '')
        return replacedDomain
    }

    const style = `flex p-3 group ${showBorder ? 'border' : ''} border-system-border-light bg-system-surface hover:bg-system-hover rounded-sm cursor-pointer`

    return (
        <div key={`source_${id}`} className={style}
            onClick={() => {
                handleOpenLink({
                    id: id,
                    url: url,
                    title: title,
                    documentLink: documentLink || metadata?.external_link,
                    documentSource: metadata?.document_source,
                    window
                })
            }}
        >
            <div className="flex flex-col gap-2 flex-grow">
                <div className="flex gap-2 items-center">
                    {isDiffbot ?
                        <div className="p-[2.5px] rounded-[2px] border border-system-border-light">
                            <Diffbot />
                        </div>
                        :
                        isIntegration ?
                            <div className="h-4 w-4 flex items-center justify-center rounded-[2px] border border-system-border-light">
                                {getIntegrationIcon(metadata.document_source_details?.integration_code_name || '', true)}
                            </div>
                            :
                            isDocument ?
                                <div className="p-[2.5px] rounded-[2px] border border-system-border-light">
                                    <FileText className="w-3 h-3" />
                                </div>
                                :
                                <img src={getIconSrc(getHostname())} className="h-4 w-4 border border-system-border-light rounded-sm" />
                    }

                    {isThirdPartyData ?
                        <TypographyLabel className="text-system-body">
                            {isDiffbot ? 'DiffBot' : metadata.source}
                        </TypographyLabel>
                        :
                        isIntegration ?
                            <div className="flex flex-col gap-1">
                                <TypographyLabel className="text-system-body">
                                    {`${getIntegrationName(metadata.document_source_details?.integration_code_name || '')}${updatedDateString ? `, ${updatedDateString}` : ''}`}
                                </TypographyLabel>
                            </div>
                            :
                            isDocument ?
                                <TypographyLabel className="text-system-body">
                                    {`${friendlyOrgName(userContext.user?.app_metadata.organization_id)}${updatedDateString ? `, ${updatedDateString}` : ''}`}

                                </TypographyLabel>
                                :
                                <TypographyLabel className="text-system-body">
                                    {getDomain(getHostname())}
                                </TypographyLabel>
                    }

                </div>
                <div className="flex gap-2 items-center">
                    {isDocument && !isDiffbot && (
                        getFileIcon(metadata?.document_type_friendly || '', 'w-6 h-6')
                    )}

                    <div className="font-label font-medium text-system-primary line-clamp-1">{isThirdPartyData ? text : title}</div>
                </div>
            </div>
            <ExternalLink className="w-6 h-6 hidden shrink-0 group-hover:block" />
        </div>
    )
}

export default Source