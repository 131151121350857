import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { useState } from "react"
import { DialogClose } from "@radix-ui/react-dialog"
import { CustomAlert } from "../CustomAlert"
import { ASYNC_STATUS, ResponseSignedUrlUpload, ResponseSignedUrlsUpload } from "@/types/types"
import { MAX_UPLOAD_FILES, WEB_SERVER_ENDPOINT } from "@/constants"
import { Badge } from "../ui/badge"

type UploadableFile = {
  file: File,
  signedUpload: ResponseSignedUrlUpload | null,
  skipUpload?: boolean,
  upload: {
    skip?: boolean,
    status: ASYNC_STATUS,
  }
}

function getStatusLabel(status: ASYNC_STATUS, fileCount: number) {
  switch (status) {
    case ASYNC_STATUS.idle:
      return `Upload files ${fileCount > 0 ? `(${fileCount})` : ""}`;
    case ASYNC_STATUS.loading:
      return "Uploading";
    case ASYNC_STATUS.success:
      return "Upload complete";
    case ASYNC_STATUS.error:
      return "Failed";
  }
}

function SelectedFile({ file, handleSkipUpload }: { file: UploadableFile, handleSkipUpload: (file: File) => void }) {
  const fileExists = file.signedUpload?.exists;
  const isUploading = file.upload.status === ASYNC_STATUS.loading;
  const isSuccess = file.upload.status === ASYNC_STATUS.success;
  const isError = file.upload.status === ASYNC_STATUS.error;
  const classes = "h-[40px] text-nowrap max-w-[200px]";
  const filenameClasses = "text-system-body overflow-hidden";
  if (isUploading) {
    return (
      <div className={classes}>
        <span>
          <Badge variant="orange" className="ml-2">uploading</Badge>
          <span className={filenameClasses}>{file.file.name}</span>
        </span>
      </div>
    )
  }

  if (isError) {
    return (
      <div className={classes}>
        <span>
          <Badge variant="red" className="ml-2">failed</Badge>
          <span className={filenameClasses}>{file.file.name}</span>
        </span>
      </div>
    )
  }

  if (isSuccess) {
    return (
      <div className={classes}>
        <span>
          <Badge variant="green" className="ml-2">uploaded</Badge>
          <span className={filenameClasses}>{file.file.name}</span>
        </span>
      </div>
    )
  }

  return (
    <div className={classes}>
      {fileExists ? <Badge variant="blue" className="mr-2">duplicate</Badge> : ""}
      {fileExists ? <span><Button variant="inline" size={"sm"} className="mr-2" onClick={() => { handleSkipUpload(file.file) }}>Skip</Button></span> : ""}
      <span className={filenameClasses}>{file.file.name}</span>
    </div>
  )
}

export function MultiFileUpload({ uploadCallback }: { uploadCallback: () => void }) {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<UploadableFile[] | null>(null);
  const [status, setStatus] = useState<ASYNC_STATUS>(ASYNC_STATUS.idle);

  async function getSignedUrls(fileNames: string[]): Promise<ResponseSignedUrlsUpload> {
    const res = await fetch(`${WEB_SERVER_ENDPOINT}/document/upload-signed-urls`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileNames }),
      credentials: "include",
    });
    return await res.json();
  }


  async function uploadSignedFile(file: File, signedUpload: ResponseSignedUrlUpload): Promise<string> {
    const res = await fetch(signedUpload.signed_url, {
      method: 'put',
      headers: {
        ...signedUpload.headers,
        "Content-Type": "application/octet-stream",
      },
      body: file,
    });
    if (!res.ok) {
      throw new Error("failed to upload file");
    }
    return await res.text();
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.currentTarget.files) return;
    const files = e.currentTarget.files;
    if (!files) return;
    if (files.length > MAX_UPLOAD_FILES) {
      alert(`You can only upload ${MAX_UPLOAD_FILES} files at a time`);
      return;
    }
    const uploadableFiles = Array.from(files).map(file => ({ file, signedUpload: null, upload: { status: ASYNC_STATUS.idle } }));
    setSelectedFiles(uploadableFiles);

    setStatus(ASYNC_STATUS.loading);
    const fileNames = uploadableFiles.map(f => f.file.name);
    getSignedUrls(fileNames).then((response) => {
      const updatedFiles = uploadableFiles.map((file) => {
        const signedUpload = response.signed_urls.find((s) => s.file_name === file.file.name) || null;
        return { ...file, signedUpload };
      });
      setSelectedFiles(updatedFiles);
      setStatus(ASYNC_STATUS.idle);
    }).catch((error) => {
      console.error(error);
      setStatus(ASYNC_STATUS.loading);
    });
  }

  function updateFileStatus(file: File, status: ASYNC_STATUS) {
    setSelectedFiles((prev) => {
      if (!prev) return prev;
      return prev.map((f) => {
        if (f.file.name === file.name) {
          return { ...f, upload: { status } };
        }
        return f;
      });
    });
  }

  function uploadFiles() {
    if (!selectedFiles) return;
    let didAnyFail = false;
    // execute all requests to upload files asynchronously
    // keep track of how many requests have completed
    // so that we can check if all requests have completed
    // then handle the final state of the dialog
    let requestsCompleted = 0;
    const totalRequests = selectedFiles.filter(s => s.skipUpload !== true).length;

    setStatus(ASYNC_STATUS.loading);

    for (let i = 0; i < selectedFiles.length; i++) {
      const s = selectedFiles[i];
      if (s.skipUpload) continue;
      if (!s.signedUpload) continue;

      updateFileStatus(s.file, ASYNC_STATUS.loading);

      uploadSignedFile(s.file, s.signedUpload)
        .then(() => {
          updateFileStatus(s.file, ASYNC_STATUS.success);
        }).catch((error) => {
          console.error(error);
          updateFileStatus(s.file, ASYNC_STATUS.error);
          didAnyFail = true;
        }).finally(() => {
          requestsCompleted++;

          if (requestsCompleted === totalRequests) {
            if (didAnyFail) {
              setStatus(ASYNC_STATUS.error);
            } else {
              setStatus(ASYNC_STATUS.success);
              setTimeout(() => {
                // close dialog
                toggleDialog();
                // refetch files
                uploadCallback();
              }, 2000);
            }
          }
        });
    }
  }

  function toggleDialog() {
    if (showDialog) {
      // reset state
      setSelectedFiles(null);
    }
    setShowDialog((prev) => !prev);
  }

  function handleSkipUpload(file: File) {
    setSelectedFiles((prev) => {
      if (!prev) return prev;
      return prev.map((f) => {
        if (f.file.name === file.name) {
          return { ...f, skipUpload: true };
        }
        return f;
      });
    });
  }

  const computedSelectedFiles = (selectedFiles || []).filter(f => f.skipUpload !== true);
  const uploadDisabled = computedSelectedFiles.length === 0 || (status !== ASYNC_STATUS.idle);
  const countFiles = computedSelectedFiles.length;
  const ctaLabel = getStatusLabel(status, countFiles);
  return (
    <Dialog open={showDialog} onOpenChange={toggleDialog}>
      <DialogTrigger asChild>
        <div>
          <Button variant={"secondary"}>
            Upload files
          </Button>
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Upload files</DialogTitle>
          <DialogDescription>
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-10 my-4">
          <div className="flex flex-col gap-4">
            <label htmlFor="file-upload">
              <Button variant='secondary' className="pointer-events-none cursor-pointer">
                Select Files
              </Button>
            </label>
            <input id="file-upload" type="file" name="file" onChange={handleFileChange} className="hidden-file-input" multiple={true} />

            <div className="max-h-[255px] overflow-y-scroll">
              {(computedSelectedFiles).map(s => (
                <SelectedFile key={s.file.name} file={s} handleSkipUpload={handleSkipUpload} />
              ))}
            </div>
          </div>
          <div>
            <CustomAlert variant="info" description="Uploaded files will be accessible by anyone within your company" />
          </div>
        </div>
        <DialogFooter className="flex flex-row justify-end gap-2 sm:gap-[unset]">
          <DialogClose>
            <Button variant={"secondary"}>Cancel</Button>
          </DialogClose>
          <Button type="submit" onClick={uploadFiles} disabled={uploadDisabled}>{ctaLabel}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
